import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';

import { ListAllLocationsLocation, RateCardLocation } from './routes';

const NotFound: FC = () => {
  // return <Redirect to={RateCardLocation.path} />;
  return <Redirect to={ListAllLocationsLocation.path} />;
};

export default NotFound;
