import { observer } from 'mobx-react';
import React, { FC } from 'react';

import PageTitle from '../../components/PageTitle';
import Form from './Form';

const RateCardScreen: FC = observer(() => {
  return (
    <>
      <PageTitle>Rate Card</PageTitle>
      <Form />
    </>
  );
});

export default RateCardScreen;
