import './assets/main.less';

import { ConfigProvider, Empty } from 'antd';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Screen from './screens';

const App: React.FC = () => {
  return (
    <Router>
      <ConfigProvider renderEmpty={() => <Empty description="Không có dữ liệu" />}>
        <Layout>
          <Screen />
        </Layout>
      </ConfigProvider>
    </Router>
  );
};

export default App;
