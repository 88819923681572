import { get } from 'lodash';
import { observable, runInAction } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { LocationCategory } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

class LayoutStore {
  @observable locationCategory: LocationCategory[];
  @observable categoryMapping: { [field: string]: string };
  @observable cityMapping: { [field: string]: string };
  @observable districtMapping: { [field: string]: string };

  constructor() {
    runInAction(() => {
      this.locationCategory = [];
      this.categoryMapping = {};
      this.cityMapping = {};
      this.districtMapping = {};
    });
  }

  @actionAsync
  public getCategories = async () => {
    const sdk = await task(getSdk());

    const { sale_findManyCategory } = await task(sdk.sale_findManyCategory());

    if (sale_findManyCategory) {
      this.locationCategory = sale_findManyCategory;
      this.categoryMapping = sale_findManyCategory.reduce((all, value) => {
        const key = get(value, 'value');
        const text = get(value, 'text');
        const icon = get(value, 'icon');

        all[key] = text;
        all[text] = icon;

        return all;
      }, {});
    }
  };

  @actionAsync
  public findCities = async () => {
    const sdk = await task(getSdk());

    const { sale_findManyCity } = await task(sdk.sale_findManyCity());

    if (sale_findManyCity) {
      this.cityMapping = sale_findManyCity.reduce((all, value) => {
        const key = get(value, 'value');
        const text = get(value, 'text');
        all[key] = text;
        return all;
      }, {});
    }
  };

  @actionAsync
  public findDistricts = async () => {
    const sdk = await task(getSdk());

    const { sale_findManyDistrict } = await task(sdk.sale_findManyDistrict());

    if (sale_findManyDistrict) {
      this.districtMapping = sale_findManyDistrict.reduce((all, value) => {
        const key = get(value, 'value');
        const text = get(value, 'text');
        all[key] = text;
        return all;
      }, {});
    }
  };
}

export default new LayoutStore();
