import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import Pictures from './Pictures';
import store from './store';

const Wrapper = styled.div({
  height: '100%',
  border: '1px solid #006299',
  borderRadius: 10,
  padding: 16,
  display: 'flex',
  flexDirection: 'column'
});

const ItemTitle = styled.label({
  color: '#99A2B1',
  fontSize: 16
});

const Info: FC = observer(() => {
  const { selectedLocation, setSelectedLocation } = store;

  if (!selectedLocation) return null;

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>
        <h3 style={{ color: '#006299' }}>{selectedLocation.name}</h3>
        <CloseOutlined style={{ marginLeft: 'auto' }} onClick={() => setSelectedLocation(null)} />
      </div>
      <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
        <ItemTitle>Address</ItemTitle>
        <br />
        <p>{selectedLocation.address?.text}</p>
        <ItemTitle>Desscription</ItemTitle>
        <p>{selectedLocation.description}</p>
        <br />
        <ItemTitle>Pictures</ItemTitle>
        <Pictures />
      </div>
    </Wrapper>
  );
});

export default Info;
