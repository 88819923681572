import { get, isEmpty } from 'lodash';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import { actionAsync, task } from 'mobx-utils';

import { LocationCategory, LocationFilter, LocationSaleFragment } from '~graphql/_sdk';
import { getSdk } from '~graphql/sdk';

import layoutStore from '../../components/Layout/store';

class LocationStore {
  @observable _locations: LocationSaleFragment[];
  @observable loading: boolean;
  @observable selectedLocation: LocationSaleFragment;
  @observable isPictureModalOpen: boolean;
  @observable selectedPictureIdx: number;
  @observable locationCategory: LocationCategory[];
  @observable categoryMapping: { [field: string]: string };
  @observable cityMapping: { [field: string]: string };
  @observable districtMapping: { [field: string]: string };

  constructor() {
    runInAction(() => {
      this._locations = [];
      this.loading = false;
      this.selectedLocation = null;
      this.isPictureModalOpen = false;
      this.selectedPictureIdx = null;
    });
  }

  @actionAsync
  public findCommon = async () => {
    const sdk = await task(getSdk());
  };

  @actionAsync
  public findLocations = async (where: LocationFilter) => {
    this.loading = true;
    const sdk = await task(getSdk());

    const checkCommon =
      isEmpty(layoutStore.cityMapping) ||
      isEmpty(layoutStore.districtMapping) ||
      isEmpty(layoutStore.categoryMapping);

    const { sale_findManyLocation } = await task(sdk.sale_findManyLocation({ where }));
    if (!checkCommon && sale_findManyLocation) {
      this._locations = sale_findManyLocation;
      this.loading = false;
      return;
    }

    const { sale_findManyCategory } = await task(sdk.sale_findManyCategory());
    const categoryMapping = sale_findManyCategory.reduce((all, value) => {
      const key = get(value, 'value');
      const text = get(value, 'text');
      const icon = get(value, 'icon');

      all[key] = text;
      all[text] = icon;

      return all;
    }, {});

    const { sale_findManyDistrict } = await task(sdk.sale_findManyDistrict());
    const districtMapping = sale_findManyDistrict.reduce((all, value) => {
      const key = get(value, 'value');
      const text = get(value, 'text');
      all[key] = text;
      return all;
    }, {});

    const { sale_findManyCity } = await task(sdk.sale_findManyCity());
    const cityMapping = sale_findManyCity.reduce((all, value) => {
      const key = get(value, 'value');
      const text = get(value, 'text');
      all[key] = text;
      return all;
    }, {});

    this._locations = sale_findManyLocation.map(item => {
      item.categoryName = categoryMapping[item.categoryId];
      item.cityName = cityMapping[item.city];
      item.districtName = districtMapping[item.district];
      return item;
    })

    this.loading = false;
  };

  @action
  setSelectedLocation = (location: LocationSaleFragment) => {
    this.selectedLocation = location;
  };

  @action
  togglePictureModal = (idx: number = null) => {
    this.selectedPictureIdx = idx;
    this.isPictureModalOpen = !this.isPictureModalOpen;
  };

  @computed
  public get locations() {
    return toJS(this._locations);
  }

  @computed
  public get pictures() {
    const pics = this.selectedLocation && toJS(this.selectedLocation.pictures);
    return pics ? pics.map(({ id, path }) => ({ id, source: path })) : [];
  }
}

export default new LocationStore();
