import React, { FC } from 'react';

const Logo: FC = () => {
  return (
    <img
      style={{ maxWidth: '60%', maxHeight: '60%', margin: 'auto', marginTop: 20, left: 0 }}
      src={require('~assets/images/logo_text.png')}
      alt="MegaNet"
    />

  );
};

export default Logo;
