import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorBoundary from '~components/UI/ErrorBoundary';

import ContactScreen from './ContactScreen';
import LocationScreen from './locations/LocationScreen';
import NotFound from './NotFound';
import RateCardScreen from './ratecard/RateCardScreen';
import { ContactLocation, ListLocationsLocation, RateCardLocation } from './routes';

const Screen: FC = () => {
  return (
    <ErrorBoundary>
      <Switch>
        {ListLocationsLocation.toRoute({ component: LocationScreen, invalid: NotFound })}
        {RateCardLocation.toRoute({ component: RateCardScreen, invalid: NotFound })}
        {ContactLocation.toRoute({ component: ContactScreen, invalid: NotFound })}
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  );
};

export default Screen;
