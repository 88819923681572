import { Layout as AntdLayout } from 'antd';
import React, { FC } from 'react';

import Sidebar from './Sidebar';

const { Content } = AntdLayout;

const Layout: FC = props => {
  const { children } = props;
  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Content style={{ marginLeft: 200, padding: 30, backgroundColor: 'white' }}>
        {children}
      </Content>
    </AntdLayout>
  );
};

export default Layout;
