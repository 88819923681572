import React, { FC } from 'react';

const TopMenuItemTitle: FC<React.HTMLAttributes<HTMLHeadingElement>> = (props) => {
  const { style = {}, children, ...otherProps } = props;

  return (
    <h3 style={{ margin: 0, color: 'inherit', ...style }} {...otherProps}>
      {children}
    </h3>
  );
};

export default TopMenuItemTitle;
