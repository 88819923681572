import React, { FC } from 'react';

import styled from '@emotion/styled';

import PageTitle from '../components/PageTitle';

const Block = styled.div({
  marginBottom: 20
})

const Label = styled.label({
  display: 'block',
  fontSize: 17,
  fontWeight: 600
})

const Text = styled.span({
  fontSize: 15,
})

const ContactScreen : FC = () => {
  return (
    <>
      <PageTitle>Contact</PageTitle>
      <Block style={{marginTop: 30}}>
        <Label>Company Name</Label>
        <Text>
          New Generation Open Source Technology Joint Stock Company / Công ty Cổ phần Công nghệ Nguồn mở Thế Hệ Mới
        </Text>
      </Block>
      <Block>
        <Label>Address</Label>
        <Text>D2-D2 That Son Street, Ward 15, District 10, HCMC</Text>
      </Block>
      <Block>
        <Label>Advertising</Label>
        <Text>0972 000 078</Text>
      </Block>
      <Block>
        <Label>Email</Label>
        <a href="mailto:info@meganet.com.vn">
          <Text>info@meganet.com.vn</Text>
        </a>
      </Block>
      <Block>
        <Label>Website</Label>
        <a href="https://meganet.com.vn" target="_blank">
          <Text>https://meganet.com.vn</Text>
        </a>
      </Block>
      <Block>
        <Label>Facebook</Label>
        <a href="https://www.facebook.com/meganet.wifimarketing" target="_blank">
          <Text>MegaNet Wifi Marketing</Text>
        </a>
      </Block>
    </>
  );
}

export default ContactScreen;
