import { Row } from 'antd';
import { css, cx } from 'emotion';
import React, { FC } from 'react';

import PictureModal from './PictureModal';
import store from './store';

const imgWrapperCls = css({
  position: 'relative',
  paddingTop: '50% !important',
  minWidth: 100
});

const imgCls = css({
  position: 'absolute',
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  top: 8,
  backgroundSize: 'cover',
  cursor: 'pointer'
});

const Pictures: FC = () => {
  const { pictures, togglePictureModal } = store;

  return (
    <>
      <Row gutter={[16, 16]}>
        {pictures.map((image, idx) => (
          <div key={image.id} className={cx('ant-col', 'ant-col-12', imgWrapperCls)}>
            <div
              className={imgCls}
              style={{ backgroundImage: `url(${image.source})` }}
              onClick={() => togglePictureModal(idx)}
            />
          </div>
        ))}
      </Row>
      <PictureModal />
    </>
  );
};

export default Pictures;
