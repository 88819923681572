import './Sidebar.less';

import { Layout } from 'antd';
import React, { FC } from 'react';

import Logo from './Logo';
import Menu from './Menu';

const { Sider } = Layout;

const Sidebar: FC = () => {
  return (
    <Sider
      style={{
        height: '100vh',
        background: '#15629a',
        position: 'fixed'
      }}
    >
      <div style={{ display: 'flex' }}>
        <Logo />
      </div>
      <Menu />
    </Sider>
  );
};

export default Sidebar;
