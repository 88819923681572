import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import store from './store';

const PictureModal: FC = observer(() => {
  const { pictures, isPictureModalOpen, selectedPictureIdx, togglePictureModal } = store;

  const handleClose = () => {
    togglePictureModal();
  };

  return (
    <ModalGateway>
      {isPictureModalOpen ? (
        <Modal onClose={handleClose}>
          <Carousel views={pictures} currentIndex={selectedPictureIdx} />
        </Modal>
      ) : null}
    </ModalGateway>
  );
});

export default PictureModal;
