import './Form.less';

import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

const Form: FC = observer(() => {
  return (
    <>
      <table className="table-rate-card">
        <thead>
          <tr>
            <th style={{ width: '1%' }}>No.</th>
            <th style={{ width: '1%' }}>Metrics</th>
            <th style={{ width: 350 }}>Format</th>
            <th style={{ width: '1%' }}>Unit</th>
            <th style={{ width: 90 }}>Group A</th>
            <th style={{ width: 90 }}>Group B</th>
            {/* <th style={{ width: 90 }}>Group C</th> */}
            <th style={{ width: 600 }}>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td rowSpan={7}>CPC</td>
            <td>Standard Banner</td>
            <td rowSpan={7}>Click</td>
            <td rowSpan={7} align="center">
              4,000
            </td>
            <td rowSpan={7} align="center">
              3,000
            </td>
            {/* <td rowSpan={7} align="center">
              2,000
            </td> */}
            <td>
              Banner click đơn giản
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/new2019/standard/"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>HTML5 Banner</td>
            <td>
              Banner hiệu ứng Html 6s xuất hiện button
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/new2019/html5/"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>Slide Banner (3 sliders max)</td>
            <td>
              Banner 03 slides
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/meganet_wfmkt/index.html"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>Form Banner - Lead Gen Banner (3 slides max)</td>
            <td>
              Banner 03 slides với 01 form điền thông tin
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/new2019/form/"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Automation Login Banner </td>
            <td>
              Banner tự động kết nối Internet sau 6s
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/new2019/vide06s/"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Scratch Banner</td>
            <td>
              Banner tương tác đơn giản (01 lần click)
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/new2019/scratchpad/index.html"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          {/* <tr>
            <td>7</td>
            <td>Login Facebook/Google Banner</td>
            <td>
              Banner có lựa chọn login bằng tài khoản Facebook/Google
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/meganet_default/facebook/index.html"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr> */}
          <tr>
            <td>7</td>
            <td>Survey Banner</td>
            <td>
              Banner có lựa chọn trả lời câu hỏi khảo sát (01 câu hỏi)
              <a target="_blank" href="https://banner-mkt.meganet.com.vn/ag/vinpearl/new2109/">
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td rowSpan={2}>CPV/Game</td>
            <td>Video 15s Banner</td>
            <td>View</td>
            <td align="center">6,000</td>
            <td align="center">4,500</td>
            {/* <td align="center">3,000</td> */}
            <td>
              Thời lượng xem video 15s (True view - Viewability 100%)
              <a
                target="_blank"
                href="https://banner-mkt.meganet.com.vn/ag/tmc/visitkorea/adventures/"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Video 15s Banner + Survey</td>
            <td>View</td>
            <td align="center">7,000</td>
            <td align="center">5,500</td>
            {/* <td align="center">4,000</td> */}
            <td>
              Thời lượng xem video 15s + 2 survey (tạm thời không áp dụng cho California)
              <a
                target="_blank"
                href="https://banner.mkt.meganet.com.vn/meganet_default/mgn_survey_video/index.html"
              >
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>10</td>
            <td>CPE</td>
            <td>Engagement Banner</td>
            <td colSpan={3}>Báo giá theo yêu cầu </td>
            <td>
              <a target="_blank" href="https://banner-mkt.meganet.com.vn/ag/murad/">
                <i className="fas fa-external-link-alt" />
              </a>
            </td>
          </tr>
          <tr>
            <td>11</td>
            <td>CPL</td>
            <td>Cost per Lead</td>
            <td colSpan={3}>Báo giá theo yêu cầu </td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>Exclussive</td>
            <td>Buy full traffic</td>
            <td colSpan={3}>Báo giá theo yêu cầu </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <Row gutter={[40, 10]} style={{ marginTop: 30, justifyContent: 'space-evenly' }}>
        <Col>
          <table className="table-category-group">
            <tr>
              <td style={{ width: 100 }}>&nbsp;</td>
              <td></td>
            </tr>
            <tr>
              <td>GROUP A</td>
              <td>Vinhomes, California Fitness, Nội Bài Airport, Tân Sơn Nhất Airport</td>
            </tr>
            <tr>
              <td>GROUP B</td>
              <td>Tất cả các khu vực còn lại</td>
              {/* <td>Airport, Shopping Mall, Kidzone, Cinema, Restaurant</td> */}
            </tr>
            {/* <tr>
              <td>GROUP C</td>
              <td>Tất cả các khu vực còn lại</td>
            </tr> */}
          </table>
        </Col>
        <Col>
          <table className="table-rate-card-note">
            <tr>
              <td>Note:</td>
            </tr>
            <tr>
              <td>- Báo giá có giá trị trong 30 ngày.</td>
            </tr>
            <tr>
              <td>- Giá chưa bao gồm thuế VAT.</td>
            </tr>
            <tr>
              <td>- Các địa điểm được mở mới liên tục, có thể chưa được cập nhật, vui lòng liên hệ trực tiếp để được tư vấn.</td>
            </tr>
          </table>
        </Col>
      </Row>
    </>
  );
});

export default Form;
