import '~assets/styles/index';

import { configure } from 'mobx';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Loading from '~components/UI/Loading';

import App from './App';

configure({ enforceActions: 'always' });

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
