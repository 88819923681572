import './Menu.less';

import { Menu } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  ContactLocation,
  ListLocationsLocation,
  RateCardLocation
} from '~apps/locations/screens/routes';
import { menu_sider as menu_side } from '~components/Layout/css';

import store from './store';
import TopMenuItemTitle from './TopMenuItemTitle';

const { Item, SubMenu } = Menu;

const Sidebar: FC = observer(() => {
  const { locationCategory, getCategories, findCities, findDistricts } = store;
  const location = useLocation();

  useEffect(() => {
    getCategories();
    findCities();
    findDistricts();
  }, []);

  const locationCategoryMenu = [
    { text: 'All', value: 'all', icon: 'fa-border-all' },
    ...locationCategory
  ].map(item => {
    const linkContent = (
      <>
        <i className={`fas fa-fw ${item.icon}`} style={{ marginRight: 10 }} />
        {item.text}
      </>
    );

    return (
      <Item key={ListLocationsLocation.toUrl({ id: item.value })}>
        {ListLocationsLocation.toLink(linkContent, { id: item.value })}
      </Item>
    );
  });

  return (
    <Menu
      style={{
        marginTop: 20,
        background: '#006299',
        color: 'white',
        maxHeight: 'calc(100% - 100px)',
        overflow: 'hidden auto'
      }}
      mode="inline"
      className={menu_side}
      selectedKeys={[location.pathname]}
    >
      <SubMenu title={<TopMenuItemTitle>Location List</TopMenuItemTitle>}>
        {locationCategoryMenu}
      </SubMenu>
      <Item key={RateCardLocation.path}>
        <Link to={RateCardLocation.path}>
          <TopMenuItemTitle>Rate Card</TopMenuItemTitle>
        </Link>
      </Item>
      <Item key={ContactLocation.path}>
        <Link to={ContactLocation.path}>
          <TopMenuItemTitle>Contact</TopMenuItemTitle>
        </Link>
      </Item>
    </Menu>
  );
});

export default Sidebar;
