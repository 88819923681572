import './Grid.less';

import { Badge } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core';
import { InfoCircleFilled } from '@ant-design/icons';
import { ACTIVE, COMING_SOON } from '@source/common';

import AgGridClient from '~components/UI/AgGrid/AgGridClient';
import { upperCaseFirstLetter } from '~utils/upperCaseFirstLetter';

import layoutStore from '../../components/Layout/store';
import store from './store';

const Grid: FC = observer(() => {
  const { id } = useParams();

  const { categoryMapping, cityMapping, districtMapping } = layoutStore;
  const { findLocations, locations, loading, setSelectedLocation } = store;

  const parseCategory = value => (categoryMapping[value] ? categoryMapping[value] : '');
  const parseCity = value => (cityMapping[value] ? cityMapping[value] : '');
  const parseDistrict = value => (districtMapping[value] ? districtMapping[value] : '');

  useEffect(() => {
    setSelectedLocation(null);
    const categoryId = id !== 'all' ? id : '';
    findLocations({ categoryId });
  }, [id, cityMapping]);

  const columnDef: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'No.',
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRenderer: ({ rowIndex }) => rowIndex + 1
    },
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      sortable: true
    },
    {
      headerName: 'Category',
      field: 'categoryId',
      minWidth: 100,
      maxWidth: 100,
      hide: id !== 'all',
      menuTabs: ['filterMenuTab'],
      sortable: true,
      filter: true,
      filterParams: {
        buttons: ['clear', 'apply'],
        cellRenderer: ({ value, data }) => {
          return `<span> <i class='fa fa-fw fas ${parseCategory(
            value
          ) || data.categoryName}' style={ margin-right: 10}> </i> ${value} </span>`;
        }
      },
      keyCreator: ({ value, data }) => parseCategory(value) || data.categoryName,
      cellRenderer: ({ value, data }) => parseCategory(value) || data.categoryName
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 80,
      maxWidth: 80,
      menuTabs: ['filterMenuTab'],
      filter: true,
      // sortable: true,
      sort: 'asc',
      filterParams: {
        buttons: ['clear', 'apply']
      },
      keyCreator: ({ value }) => upperCaseFirstLetter(value),
      cellRendererFramework: ({ value }) => {
        let status: PresetStatusColorType = 'success';
        if (value === COMING_SOON) {
          status = 'warning';
        } else if (value !== ACTIVE) {
          status = 'default';
        }
        return (
          <Badge
            status={status}
            text={upperCaseFirstLetter(value === COMING_SOON ? 'CS' : value)}
          />
        );
      }
    },
    {
      headerName: 'City',
      field: 'city',
      minWidth: 100,
      maxWidth: 100,
      menuTabs: ['filterMenuTab'],
      filter: true,
      sortable: true,
      filterParams: {
        buttons: ['clear', 'apply']
      },
      keyCreator: ({ value, data }) => parseCity(value) || data.cityName,
      cellRenderer: ({ value, data }) => parseCity(value) || data.cityName
    },
    {
      headerName: 'District',
      field: 'district',
      minWidth: 120,
      maxWidth: 130,
      menuTabs: ['filterMenuTab'],
      filter: true,
      sortable: true,
      filterParams: {
        buttons: ['clear', 'apply']
      },
      keyCreator: ({ value, data }) => parseDistrict(value) || data.districtName,
      cellRenderer: ({ value, data }) => parseDistrict(value) || data.districtName
    },
    {
      headerName: 'Monthly Click',
      field: 'monthlyClick',
      minWidth: 100,
      maxWidth: 100,
      cellClass: 'text-right',
      // sortable: true,
      sort: 'desc',
      cellRendererFramework: ({ value, data }) => {
        if (data.status === COMING_SOON) {
          return 0;
        }
        return `${value ? value.toLocaleString() : 0}`;
      }
    },
    {
      headerName: 'APs',
      field: 'noOfAccessPoint',
      minWidth: 60,
      maxWidth: 60,
      sortable: true,
      cellClass: 'text-right',
      cellRenderer: ({ value }) => `${value ? value.toLocaleString() : 0}`
    },
    {
      headerName: '',
      field: '_id',
      minWidth: 50,
      maxWidth: 50,
      cellRendererFramework: ({ data }) => {
        return (
          <InfoCircleFilled
            style={{ fontSize: 18, color: 'rgba(33, 52, 85, 0.6)' }}
            onClick={() => setSelectedLocation(data)}
          />
        );
      }
    }
  ];

  const options: GridOptions = {
    pagination: false,
    suppressCellSelection: true,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left',
          key: 'totalRowComponent'
        }
      ]
    }
  };

  return (
    <div className="grid-locations" style={{ height: '100%' }}>
      <AgGridClient
        columnDefs={columnDef}
        rowData={locations}
        loading={loading}
        height="100%"
        {...options}
      />
    </div>
  );
});

export default Grid;
