import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { useParams } from 'react-router-dom';

import layoutStore from '../../components/Layout/store';
import PageTitle from '../../components/PageTitle';
import Grid from './Grid';
import Info from './Info';
import store from './store';

const Screen: FC = observer(() => {
  const { id } = useParams();

  const { categoryMapping } = layoutStore;
  const { selectedLocation, setSelectedLocation } = store;

  useEffect(() => {
    return () => setSelectedLocation(null);
  }, []);

  return (
    <>
      <Row style={{ height: '100%', flexDirection: 'column', flexWrap: 'nowrap' }}>
        <Col style={{ paddingBottom: 0, paddingTop: 0, flex: '0 0 auto' }}>
          <PageTitle>{id === 'all' ? 'Full List' : categoryMapping[id]}</PageTitle>
        </Col>
        <Row gutter={[16, 16]} style={{ flex: '1 1 auto' }}>
          <Col span={selectedLocation ? 16 : 24}>
            <Grid />
          </Col>
          <ReactResizeDetector handleHeight>
            {({ height }) => (
              <Col span={selectedLocation ? 8 : 0} style={{ height }}>
                <Info />
              </Col>
            )}
          </ReactResizeDetector>
        </Row>
      </Row>
    </>
  );
});

export default Screen;
